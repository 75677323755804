import React from "react";
import AboutMe from "../../components/AboutMe";
import PageHeader from "../../components/PageHeader";
import { useNavigate } from "react-router-dom";

const About = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <div className="full-screen">
      <div className="about">
        <PageHeader title="Yes, this is me" handleClick={handleClick} />
        <AboutMe />
      </div>
    </div>
  );
};

export default About;
