import Header from "./components/Header";
import AnimatedRoutes from "./components/AnimatedRoutes";
import React from "react";

function App() {
  return (
    <>
      {/*  <Header /> */}
      <AnimatedRoutes />
    </>
  );
}

export default App;
