// @ts-nocheck
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useInView } from "react-intersection-observer";
import Modal from "react-modal";

const styles = {
  italicText: {
    fontStyle: "italic",
    fontSize: "12px",
    color: "#7f7f7f",
  },
  italicTextWhite: {
    fontStyle: "italic",
    fontSize: "12px",
    color: "#fff",
  },
  modalOverlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
  modalContent: {
    backgroundColor: "#101010",
    color: "#9f9f9f",
    padding: "4vw",
    display: "flex",
    flexDirection: "column",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 99,
    borderRadius: 15,
    borderColor: "#101010",
    width: "55%",
    height: "55vh",
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 30,
  },
  logo: {
    cursor: "pointer",
    width: "50px",
    height: "50px",
    marginRight: "10px",
    border: "1px solid black",
    borderRadius: "10px",
    backgroundColor: "white",
  },
  closeButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    cursor: "pointer",
    backgroundColor: "white",
    borderRadius: "50%",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  },
  closeIcon: {
    width: "24px",
    height: "24px",
    color: "black",
  },
  modalContentMobile: {
    padding: "6vw", // Increased padding for smaller screens
    maxWidth: "80vw", // Slightly smaller for extra space
    maxHeight: "80vh", // Adjusted for smaller screen height
  },
};

const LinkList = ({ links, isValidUrl }) =>
  links.map((item, index) => (
    <div key={index} style={{ marginBottom: 10 }}>
      <p className="tech text-center">
        <em style={styles.italicText}>{item.title}:</em>
      </p>
      <p className="tech text-center">
        {isValidUrl(item.link) ? (
          <a href={item.link} target="_blank" rel="noopener noreferrer">
            <em>{item.link}</em>
          </a>
        ) : (
          <em>{item.link}</em>
        )}
      </p>
      <p className="tech text-center">
        <em style={styles.italicText}>{item.description}</em>
      </p>
    </div>
  ));

const Project = ({ project }) => {
  const [ref, inView] = useInView({ threshold: 0.5, triggerOnce: true });
  const [showModal, setShowModal] = useState(false);
  const isMobile = window.innerWidth <= 768;

  // Dynamically apply modal styles
  const modalStyles = isMobile
    ? { ...styles.modalContent, ...styles.modalContentMobile }
    : styles.modalContent;

  const {
    company,
    technologies,
    bgcolor,
    category,
    title,
    links,
    projectLogo,
    description,
  } = project;

  Modal.setAppElement("#root");

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const isValidUrl = (url) => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "\n]+$/;
    return urlRegex.test(url);
  };

  return (
    <div ref={ref} className="col-sm-12 col-lg-6">
      <div
        style={{ backgroundColor: bgcolor, height: "175px" }}
        className="projectCard d-flex flex-column align-items-start justify-content-center"
        onClick={handleOpenModal}
      >
        <h3 className="companyTitle">{company?.companyName}</h3>
        <h3 className="projectTitle text-center">{title}</h3>
        <h2 className="category">{category}</h2>
        <em style={styles.italicTextWhite}>{technologies}</em>
      </div>

      <Modal
        isOpen={showModal}
        onRequestClose={handleCloseModal}
        style={{
          overlay: styles.modalOverlay,
          content: modalStyles,
        }}
      >
        <div style={styles.modalHeader}>
          <img
            src={projectLogo}
            onClick={handleCloseModal}
            style={styles.logo}
          />
          <h3 className="modalTitle" style={{ fontSize: "1.5rem" }}>
            {title}
          </h3>
          <div style={{ flex: 1 }} />
          <div onClick={handleCloseModal} style={styles.closeButton}>
            <FontAwesomeIcon icon={faTimes} style={styles.closeIcon} />
          </div>
        </div>
        <p className="projectModalTitle">Project Description</p>
        <p className="projectDescription">{description}</p>
        <p className="projectModalTitle">Links</p>
        <LinkList links={links} isValidUrl={isValidUrl} />
      </Modal>
    </div>
  );
};

export default Project;
