import React from "react";
import PageHeader from "../../components/PageHeader";
import { Link, useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  };

  return (
    <div className="full-screen">
      <div className="about">
        <PageHeader title="404 Page Not Found" handleClick={handleClick} />
        <div className="error-description">
          <div className="row">
            <div className="col">
              <p>Sorry, the page you are looking for does not exist.</p>
              <Link to="/" className="home">
                <button className="btn">Return to Home</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
