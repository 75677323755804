import React from "react";

const SocialIcons = () => {
  return (
    <div className="socialIcons">
      <a className="icon" href="https://github.com/ihsanktmr">
        <i
          className="fa-brands fa-github"
          aria-hidden="true"
          title="Github"
        ></i>
      </a>
      <a className="icon" href="https://www.linkedin.com/in/ihsanktmr/">
        <i
          className="fa-brands fa-linkedin"
          aria-hidden="true"
          title="LinkedIn"
        ></i>
      </a>
    </div>
  );
};

export default SocialIcons;
