import projectData from "./projectsData.json";
import Project from "../../components/Project";
import PageHeader from "../../components/PageHeader";
import React from "react";
import { useNavigate } from "react-router-dom";

const Portfolio = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  };

  const ProjectList = () =>
    projectData.map((project, i) => <Project key={i} project={project} />);

  return (
    <div className="full-screen">
      <div className="portfolio">
        <PageHeader title="Projects" handleClick={handleClick} />
        <div className="row">
          <ProjectList />
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
