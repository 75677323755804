import React from "react";

const PageHeader = ({ title, handleClick }) => (
  <div className="header-container">
    <button className="backButton" onClick={handleClick}>
      <i className="fas fa-arrow-left backIcon"></i>
    </button>
    <h3 className="pageTitle">{title}</h3>
  </div>
);

export default PageHeader;
