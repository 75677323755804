import SocialIcons from "../../components/SocialIcons";
import React from "react";
import { useNavigate } from "react-router-dom";

const Landing = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/about");
  };

  return (
    <div className="full-screen">
      <div className="landing">
        <div className="textContainer">
          <h1 className="name">Hey👋</h1>
          <h2 className="description">I'm Ihsan, a React Native Engineer</h2>

          <div className="bottom-container">
            <button className="text-button about-me-btn" onClick={handleClick}>
              About Me
              <i className="fas fa-arrow-right"></i> {/* Example of an icon */}
            </button>
            <SocialIcons />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
